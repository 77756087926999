<template>
  <div>
    <section class="banner">
      <Head @closeMenu="closeOpenMenu" />
    </section>
      <router-view />
    <Menu ref="menu" />
    <Topreturn @closeMenu="closeOpenMenu"></Topreturn>
    <Footer></Footer>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Menu from "@/components/menu.vue";
import Footer from "@/components/footer.vue";
import Topreturn from "@/components/Topreturn.vue";
export default {
  data() {
    return {};
  },
  components: {
    Head,
    Menu,
    Footer,
    Topreturn,
  },
  methods: {
    closeOpenMenu(t) {
      console.log(t);
      this.$refs.menu.menuShow = t;
    },
  },
};
</script>

<style lang="scss">
.banner {
  width: 100%;
  height: 1080px;
  background-image: url("../../assets/image/enterpriseNews/enterpriseNews.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
</style>